import React, { useRef, useEffect, useState } from "react";
import { getCookie } from "../../utils/cookie"
import Sidebar from '../../components/Sidebar'
import arrow_left from '../../img/arrow_left.png'
import arrow_right from '../../img/arrow_right.png'
import search from '../../img/search-icon.png'
import { useNavigate  } from "react-router-dom";

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const Inquiry = () => {
  const navigate = useNavigate();
const sel_lang = "KR"
  const ref_CH = useRef<HTMLInputElement>(null);
  const [cHeight, setCheight] = useState('');

  const [inquiryList, setInquiryList] = useState<List>();


  let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    

    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')
    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')
    }

    getInquiryList(0)
  }, [])


  const getInquiryList = async (p: any) => {
    try {
      const data = await (await fetch(`https://playcon.net/api/admin/inquiry/list/all/${sel_lang}/${p}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      console.log(data)
      setInquiryList(data)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const move = (id:any) =>{
    navigate(`/admin/inquiry/detail/${id}`)
  }


  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>문의 내역 리스트</p>

          <div className={cHeight}>


            <div className="p-[35px]">
              <div className="">
                <div className='hidden'>
                  <label className='relative'>
                    <input type="text" className='border border-solid border-[#313a46] pl-[14px] pr-[46px] py-[20px] w-[410px] h-[10px] rounded text-[14px] placeholder:text-[#313a46]' placeholder='제목으로 검색 가능' />
                    <img src={search} alt="" className='absolute top-[4px] left-[374px]' />
                  </label>
                  <button className='w-[150px] h-[44px] bg-[#313a46] rounded-[4px] text-[14px] text-[#ffffff]'>+ 등록</button>
                </div>
                <div className='float-right pb-[15px] relative hidden'>

                  <button className='w-[36px] h-[36px] text-[12px] border border-[#dddddd]'>
                    ▲
                  </button>
                  <button className='w-[36px] h-[36px] ml-[6px] text-[12px] border border-[#dddddd]'>
                    ▼
                  </button>
                  <div className='absolute left-[78px] top-[-116px] '>
                    {/* hidden 추가 하여 toggle */}
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-[#dddddd]'>
                      최신순 ▼
                    </button>
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                      최신순
                    </button>
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                      등록일순
                    </button>
                  </div>
                  <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border border-[#dddddd]'>
                    최신순 ▼
                  </button>

                </div>
                <table className="table-auto w-full -[15px]">
                  <thead>
                    <tr>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] rounded-tl text-[#000]"><input type='checkbox' /></th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">번호</th>
                      {/* <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">문의언어</th> */}
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">문의유형</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">이름</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">회사명</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">연락처</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">이메일</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">등록일</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">내용보기</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] rounded-tr text-[#000]">답변상태</th>
                    </tr>
                  </thead>

                  <tbody className="bg-white dark:bg-slate-800">


                  {typeof inquiryList !== 'undefined' ? (
                      <>
                        {Object.keys(inquiryList.data).map((NO, index) => (
                              <tr className="hover:bg-[#eef2f7]" onClick={(e)=> {move(inquiryList.data[index].inquiry_id)}}>
                                <td className="py-[5px] px-[10px] text-[14px] h-[68px] align-middle border-b-[2px] border-solid border-[#dddddd]"><input type='checkbox' /></td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].inquiry_id}</td>
                                {/* <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].lang_cd}</td> */}
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].inquiry_type_name}</td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].name}</td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].company_name}</td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].tel}</td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].email}</td>
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].created_.slice(0,10)}</td>
                                <td title={inquiryList.data[index].content} className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd] truncate max-w-[200px] hover:underline hover:underline-offset-4 hover:cursor-pointer">
                                {inquiryList.data[index].content}
                                </td>                  
                                <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{inquiryList.data[index].status}</td>
                      
                              </tr>

                       ))}
                      </>
                    ) : null}


           

                  </tbody>
                </table>
                <div className='w-full flex flex-nowrap justify-center mt-[20px] hidden'>
                  <ul>
                    <li className='inline-block mr-[42px]'>
                      <img src={arrow_left} alt=""></img>
                    </li>
                    <li className='inline-block w-[28px] h-[28px] bg-[#313a46] text-[#ffffff] text-center'>
                      <p>1</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>2</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>3</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>4</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>5</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>6</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>7</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>8</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>9</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>10</p>
                    </li>
                    <li className='inline-block ml-[42px]'>
                      <img src={arrow_right} alt=""></img>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </>
  );
}
export default Inquiry;

