export const getCookie = (name:string) : string => {
    let matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) : "";
  };
  
  export const setCookie = (name:string, value:any, options = {}) => {
    options = {
      path: "/",
    //   domain: import.meta.env.PROD ? ".toonmaker.co.kr" : "127.0.0.1",
      // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
      ...options,
    };
  
    if ((options as any).expires instanceof Date) {
      (options as any).expires = (options as any).expires.toUTCString();
    }
  
    let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = (options as any)[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
  };
  
  export const deleteCookie = (name:string) => {
    setCookie(name, "", {
      "max-age": -1,
    });
  };
  
  