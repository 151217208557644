
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router"
import Sidebar from '../../components/Sidebar'
import { getCookie } from "../../utils/cookie"
import { useNavigate } from "react-router-dom";

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}


const InquiryDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const sel_lang = "KR";
  const ref_CH = useRef<HTMLInputElement>(null);

  const [cHeight, setCheight] = useState('');
  const [langList, setLangList] = useState<List>();
  const [detail, setDetail] = useState<List>();
  const [content, setContent] = useState("");

  const getLangList = async () => {
    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setLangList(data);

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const getDetail = async (p: any) => {

    try {

      const data = await (await fetch(`https://playcon.net/api/admin/inquiry/detail/${sel_lang}/${p}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();
      setDetail(data)

      if (data.data["answer"].length) {

        setContent(data.data["answer"][0].content)
      }


    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }


  useEffect(() => {
    getLangList();
  }, [])

  useEffect(() => {
    getDetail(id)
  }, [id])

  useEffect(() => {
    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')
    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')
    }
  }, [])

  const answer = async () => {

    const fd = new FormData();
    fd.append("inquiry_id", id + "")
    fd.append("lang_cd", detail?.data["detail"][0].lang_cd)
    fd.append("content", content)

    const data = await (await fetch(`https://playcon.net/api/admin/inquiry/answer`, {
      method: 'PUT',
      body: fd,
      headers: {
        Authorization: "Bearer " + getCookie("accessToken"),
      },
    })).json();

    console.log(data)
    if (data.message === "Update Success") {
      alert("답변완료")
      navigate("/admin/inquiry");
    } else {
      alert("오류발생")
    }

  }

  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>문의 사항 상세페이지</p>

          <div className='bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]'>
            <div className='p-[35px]'>
              {typeof detail !== 'undefined' ? (<>



                {/* 문의언어 */}
                <div className="h-[60px] flex border border-solid rounded-t-[8px] border-[#dddddd]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">문의언어</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].lang_cd} disabled></input>
                  </div>
                </div>

                {/* 문의유형 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">문의유형</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].inquiry_type_name} disabled></input>
                  </div>
                </div>


                {/* 이름 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">이름</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].name} disabled></input>
                  </div>
                </div>

                {/* 회사명 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">회사명</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].company_name} disabled></input>
                  </div>
                </div>

                {/* 연락처 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">연락처</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].tel} disabled></input>
                  </div>
                </div>

                {/* 이메일 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">이메일</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].email} disabled></input>
                  </div>
                </div>
                {/* 등록일 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">등록일</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].created_.slice(0, 10)} disabled></input>
                  </div>
                </div>
                {/* 내용 */}
                <div className="h-[230px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">내용(한국어) </p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <textarea className="border border-[#dddddd] rounded block w-[496px] h-full p-[10px] text-[14px] resize-none disabled:bg-[#ffff] disabled:border-0" value={detail.data["detail"][0].content} disabled></textarea>
                  </div>
                </div>
                {/* 답변상태 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px] rounded-b">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">답변상태</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px] disabled:bg-[#ffff]  disabled:border-0" value={detail.data["detail"][0].status} disabled></input>
                  </div>
                </div>

                {/* 문의답변 */}
                <div className="h-[230px] flex border border-solid border-[#dddddd] border rounded mt-[80px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">문의답변</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                 
                      <textarea className="border border-[#dddddd] rounded block w-[496px] h-full p-[10px] text-[14px] resize-none disabled:bg-[#ffff]" onChange={(e) => { setContent(e.target.value) }} value={content}></textarea>
                   


                  </div>
                </div>
              </>) : null}
              <div className="flex justify-center mt-[50px]">
              {typeof detail !== 'undefined' ? (<>
                {detail.data["answer"].length ? (
                  <button type="button" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff]" onClick={(e) => { answer() }}>수정</button>
                ) : (
                  <button type="button" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff]" onClick={(e) => { answer() }}>등록</button>
                )}



              </>) : null}

              <button type="button" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff] ml-[10px]">취소</button>
            </div>
          </div>
        </div>
      </div>
    </div >
    </>
  );
}
export default InquiryDetail;