
import React, { useRef, useState, useEffect } from "react";
import Sidebar from '../../components/Sidebar'
import IconCalendar from '../../img/icon-calendar.png'
import { getCookie } from "../../utils/cookie"
import filedelete from '../../img/file_delete.png'
import { useForm, Form, FormSubmitHandler, FieldValues } from "react-hook-form"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router"

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const getToday = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;


  return theBlob as File;
}

const sleep = (delay: any) => new Promise(resolve => setTimeout(resolve, delay));

const NoticeModify = () => {
  const sel_lang = "KR"
  const { id } = useParams();

  const navigate = useNavigate();

  const [langList, setLangList] = useState<List>();
  const [detail, setDetail] = useState<List>();

  const ref_selFile = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<(File | null)[]>([]);




  const getDetail = async (p: any) => {

    try {
      const aa = [...fileList]
      const data = await (await fetch(`https://playcon.net/api/admin/notice/detail/${p}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();
      setDetail(data)

      for (let i = 0; i < data.data["files_KR"].length; i++) {
        const init = await fetch(`https://playcon.net/api/common/file/download/${data.data["files_KR"][i].file_id}`, { method: "get" })
        const blob = await init.blob()
        var file = new File([blob], `${data.data["files_KR"][i].file_ori_name}`);
        aa.push(file)
      }

      setFileList(aa)

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }


  const exec_write = async (fdData: FormData) => {


    try {
      let list = [...fileList]

      for (let i = 0; i < list.length; i++) {
        console.log(list[i] as File)
        fdData.append("file_" + i, list[i] as File)
      }



      fdData.append("notice_id", id + "")
      const data = await (await fetch('https://playcon.net/api/admin/notice/update', {
        method: 'PUT',
        body: fdData,
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();


      if (data.message === "Insert Success") {
        alert("작성완료")
        navigate("/admin/notice");
      } else {
        alert("작성중 오류발생")
      }


    } catch (error) {
      console.error(error);


    } finally {
      console.log('finish')
    }
  }


  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmitForm: FormSubmitHandler<FieldValues> = ({ formData, data }) => {
    exec_write(formData)
    //console.log(data)

  };

  const getLangList = async () => {
    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setLangList(data);

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }


  useEffect(() => {
    getLangList();
  }, [])

  useEffect(() => {
    getDetail(id)

  }, [id])
  useEffect(() => {
console.log("evt")
  if(langList?.data != null){

    setValue("notice_ymd", detail?.data["detail_KR"][0].notice_ymd.slice(0,10));

    if(detail?.data["detail_KR"][0].top_fixed === "Y"){
      setValue("top_fixed", true);
    }else{
      setValue("top_fixed",false);
    }

    for (var i = 0; i < Object.keys(langList?.data).length; i++) {

      setValue("title_"+Object.keys(langList?.data)[i], detail?.data["detail_"+Object.keys(langList?.data)[i]][0].title);
      setValue("content_"+Object.keys(langList?.data)[i], detail?.data["detail_"+Object.keys(langList?.data)[i]][0].content);
    }
 
  }
    




  }, [langList, detail])


  const ref_CH = useRef<HTMLInputElement>(null);
  const [cHeight, setCheight] = useState('');


  useEffect(() => {


    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')
    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')
    }
  }, [])

  const [selDate, setSelDate] = useState(getToday());
  const clk_date = useRef<HTMLInputElement>(null);


  const uptDate = (e: any) => {
    setSelDate(e.target.value);
  }

  const openDatePicker = () => {
    clk_date?.current?.showPicker();
  }

  const openFile = () => {
    ref_selFile?.current?.click();
  }


  const selectedFile = (e: any) => {

    const len = e?.target?.files.length;

    for (let i = 0; i < len; i++) {
      //arr_files.push(e?.target?.files[i])

      //  arr_filesNames.push(e?.target?.files[i].name)

      let list = [...fileList]
      console.log(e?.target?.files[i])
      list.push(e?.target?.files[i])
      setFileList(list)
    }

  }

  const fileUpdate = (idx: any) => {

    let list = [...fileList]
    var deletedIndex = idx;
    list = list.filter(function (_, index) {
      return index !== deletedIndex
    });
    console.log(list)
    setFileList(list)

  }
  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>공지사항 수정페이지</p>
          <Form
            control={control}
            className=""
            onSubmit={onSubmitForm}
          >
            <div className={cHeight}>

              <div className='p-[35px]'>


                {/* 제목 */}


                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>
                      {index >= 1 ? (
                        <div className="h-[60px] flex border border-solid border-[#dddddd]">
                          <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                            <p className="text-[14px] text-[#000000] font-bold">제목{ }({langList.data[Object.keys(langList.data)[index]]})</p>
                          </div>
                          <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                            <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]" {...register("title_" + Object.keys(langList.data)[index], {
                              required: true,
                            })}
                              defaultValue={detail?.data["detail_" + Object.keys(langList.data)[index]][0].title} />
                          </div>
                        </div>
                      ) :
                        <div className="h-[60px] flex border border-solid rounded-t-[8px] border-[#dddddd]">
                          <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                            <p className="text-[14px] text-[#000000] font-bold">제목({langList.data[Object.keys(langList.data)[index]]})</p>
                          </div>
                          <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                            <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]" {...register("title_" + Object.keys(langList.data)[index], {
                              required: true,
                            })}
                              defaultValue={detail?.data["detail_" + Object.keys(langList.data)[index]][0].title} />
                          </div>
                        </div>
                      }


                    </div>))}
                  </>
                ) : null}



                {/* 작성일자 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">작성일자</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <label className="relative">
                      <img src={IconCalendar} alt="" className="absolute top-[11.5px] left-[10px] " />
                      <input type="text" className="border border-[#dddddd] rounded block w-[202px] h-[38px] pl-[34px] pr-[10px] text-[14px] text-[#cccccc]" onClick={() => { openDatePicker(); }} value={selDate} {...register("notice_ymd", {
                        required: true,
                      })} defaultValue={detail?.data["detail_KR"][0].notice_ymd} readOnly></input>
                    </label>


                    <input type="date" className="absolute top-[0px] left-[0px] block w-[0px] h-[0px] px-[10px] text-[14px]" ref={clk_date} onChange={(e: any) => { uptDate(e) }}></input>

                  </div>
                </div>
                {/* 상단고정 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">상단고정</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] flex items-center justify-start ">
                    <label className="ml-[10px]">

                 
                        <input type="checkbox" {...register("top_fixed", {
                          required: false,
                        })}></input>
                    
                      <span className="text-[14px] ml-[5px]">고정</span>
                    </label>
                  </div>
                </div>
                {/* 내용 */}

                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>



                      <div className={`h-[230px] flex border border-solid border-[#dddddd] border-t-[0px] mt-[10px]`}>
                        <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">내용({langList.data[Object.keys(langList.data)[index]]})</p>
                        </div>
                        <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                          <textarea className="border border-[#dddddd] rounded block w-[496px] h-full p-[10px] text-[14px]" {...register("content_" + Object.keys(langList.data)[index], {
                            required: false,
                          })} defaultValue={detail?.data["detail_" + Object.keys(langList.data)[index]][0].content} ></textarea>

                        </div>
                      </div>

                    </div>))}
                  </>
                ) : null}




                {/* 첨부파일 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px] rounded-b">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">첨부파일</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] flex items-center justify-start pl-[10px] relative">
                    <div className="border border-solid border-[#dddddd] rounded w-[496px] h-[38px] flex items-center justify-start pl-[10px]">
                      <button type="button" className="w-[77px] h-[26px] bg-[#efefef] border text-[#222222] text-[12px] border-[#767676]" onClick={openFile}>파일 선택</button>

                      <span className="text-[12px] text-[#999999] ml-[5px]">파일을 선택하세요</span>
                    </div>


                    {fileList.map((NO, index) => (<div key={index}>


                      <div className="ml-[5px]">
                        <span className="text-[12px] text-[#0066ff]">{fileList[index]?.name}
                          <img src={filedelete} alt="" className="inline mt-[-2px] ml-[2px]" onClick={() => fileUpdate(index)} />
                        </span>
                      </div>

                    </div>))}




                  </div>
                </div>

                <div className="flex justify-end mt-[50px]">
                  <button type="submit" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff]">수정</button>
                  <button type="button" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff] ml-[10px]" onClick={(e)=>{navigate("/admin/notice");}}>취소</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <input type="file" className="w-[0px] h-[0px] absolute" ref={ref_selFile} onChange={selectedFile} />
    </>
  );
}
export default NoticeModify;