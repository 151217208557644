
import logo_white from '../img/logo-playcon-white.png';
import { deleteCookie, getCookie } from "../utils/cookie"
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Unix_timestampConv = () => {
    return Math.floor(new Date().getTime() / 1000);
}

const Sidebar = () => {
    const [loc, setLoc] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const checkCookie = () => {
            if (getCookie("accessToken") !== "") {

                if (Unix_timestampConv() >= JSON.parse(atob(getCookie("accessToken").split(".")[1])).exp) {
                    deleteCookie("accessToken")
                    navigate("/admin/login");
                }
            } else {
                navigate("/admin/login");
            }
        }
        checkCookie()
    }, [navigate])


    const location = useLocation();


    useEffect(() => {

        setLoc(location.pathname)
    }, [location])

    return (
        <div className='w-[260px]'>
            <div className='bg-[#313a46] h-full text-[#ffffff] border border-solid border-[#707070] pl-[35px] py-[30px]'>
                <img src={logo_white} alt=""></img>
                <ul className='mt-[70px] '>

                    <li className={`text-[20px] pr-[35px] ${loc === "/admin/main" ? "border-r border-r-[3px] border-solid border-r-[#39ADEF]" : null}`}>
                        <Link to="/" className='w-full block'>홈</Link>
                    </li>

                    <li className="text-[20px] pr-[35px] mt-[30px]">
                        <span className='pt-[10px] border-t border-solid border-[#5b5b5b] w-full block'>히스토리(연혁)</span>
                    </li>

                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/history" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/history" className='w-full block'>리스트</Link>
                    </li>

                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/history/write" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/history/write" className='w-full block'>등록</Link>
                    </li>

                    <li className="text-[20px] pr-[35px] mt-[30px]">
                        <span className='pt-[10px] border-t border-solid border-[#5b5b5b] w-full block'>IP리스트</span>

                    </li>
                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/ip" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/ip">리스트</Link>
                    </li>

                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/ip/write" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/ip/write">등록</Link>
                    </li>

                    <li className="text-[20px] pr-[35px] mt-[30px]">
                        <span className='pt-[10px] border-t border-solid border-[#5b5b5b] w-full block'>공지사항</span>
                    </li>

                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/notice" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/notice">리스트</Link>
                    </li>

                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/notice/write" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/notice/write">등록</Link>
                    </li>

                    <li className="text-[20px] pr-[35px] mt-[30px]">
                        <span className='pt-[10px] border-t border-solid border-[#5b5b5b] w-full block'>문의</span>
                    </li>
                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/inquiry/type" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/inquiry/type">유형 리스트</Link>
                    </li>
                    <li className={`text-[18px] text-[#cccccc] mt-[18px] ${loc === "/admin/inquiry" ? "border-r border-r-[3px] border-solid border-[#39ADEF]" : null}`}>
                        <Link to="/admin/inquiry">문의 내역</Link>
                    </li>

                    <li className={`mt-[30px] text-[20px] pr-[35px] ${loc === "/admin/familysite" ? "border-r border-r-[3px] border-solid border-r-[#39ADEF]" : null}`}>
                        <Link to="/admin/familysite" className='pt-[10px] w-full block border-t border-t-[1px] border-solid border-[#5b5b5b]'>패밀리사이트</Link>
                    </li>



                </ul>

            </div>
        </div>

    );
}
export default Sidebar;