
import logo from '../../img/logo-playcon.png'
import { useForm, Form, FormSubmitHandler } from "react-hook-form"
import { setCookie, deleteCookie, getCookie } from "../../utils/cookie"
import { useEffect,useState } from 'react'
import { useNavigate } from "react-router-dom";
type FindForm = {
    userid: string
    password: string
}


const Unix_timestampConv = () => {
    return Math.floor(new Date().getTime() / 1000);
}

const Login = () => {
    const [misMatch,setMismatch] = useState(false);
    const navigate = useNavigate();

    const run_login = async (fdData: FormData) => {
       
        deleteCookie("accessToken");
        try {
            

            const data = await (await fetch('https://playcon.net/api/admin/member/login/', {
                method: 'POST',
                body: fdData,
            })).json();
            const cookieoptions = {
                expires: 60 * 60 * 9,
                //     secure:true
            }
            setCookie("accessToken", data.data.AccessToken, cookieoptions);
            navigate("/admin/main");
         
        } catch (error) {
            console.error(error);
            setMismatch(true);

        } finally {
            console.log('finish')
        }
    }


    const {
        register,
        control,
        formState: { errors },
    } = useForm<FindForm>()

    const onSubmitFindForm: FormSubmitHandler<FindForm> = async ({
        formData,
        data,
    }) => {
        
        run_login(formData)
    };

    useEffect(() => {
        const checkCookie = () =>{
            if (getCookie("accessToken") !== "") {

                if (Unix_timestampConv() >= JSON.parse(atob(getCookie("accessToken").split(".")[1])).exp) {
                    deleteCookie("accessToken")
                } else {
                    console.log("?")
                    navigate("/admin/main");
                }
            }
        }
        checkCookie();
    }, [navigate])





    return (
        <div className="bg-[#349aec] h-screen overflow-hidden flex items-center justify-center">
            <div className="bg-white w-[450px] h-[520px] shadow rounded">

                <Form className="p-[50px]" control={control} onSubmit={onSubmitFindForm}>

                    <div className="flex items-center justify-center mt-[10px]">
                        <img src={logo} alt=""></img>
                    </div>

                    <div className="flex items-center mt-[73px]">
                        <input type="text" id="userid" className="border rounded focus:outline-none w-[358px] h-[50px] px-[20px]" placeholder="아이디"{...register("userid", { required: true })} onChange={e=>setMismatch(false)} />
                    </div>

                    <div className="flex items-center">
                        <input type="password" id="password" className="border mt-[10px] rounded focus:outline-none w-[358px] h-[50px] px-[20px]" placeholder="비빌번호" {...register("password", { required: true })} onChange={e=>setMismatch(false)}  />
                    </div>

                    <div className="mt-[15px] ">


                        {/* invisible 주면 가려짐 */}
                        <span className='text-[13px] text-[#ff0000]'>
                            {errors.userid?.type === "required" && !misMatch  ? (
                                <span className='text-left float-left'>아이디 입력을 확인하세요!</span>
                            ) : null}

                            {errors.userid?.type !== "required" && errors.password?.type === "required" && !misMatch ? (
                                <span className='text-left float-left'>패스워드 입력을 확인하세요!</span>
                            ) : null}

                            { misMatch ? (
                                <span className='text-left float-left'>회원정보를 찾을수 없습니다!</span>
                            ) : null}


                            <span className='text-left float-left'>&nbsp;</span>

                        </span>

                    </div>

                    <button type="submit" className="bg-[#2e7ee9] text-white w-full rounded mt-[39px] h-[54px] font-bold">로그인</button>

                    <div className="mt-[20px]">
                        <span className='text-[13px]'>
                            <span className='text-left float-left'>Tel &nbsp; 070-8825-5004</span>
                            <span className='text-right float-right'>e-Mail &nbsp; info@ideaconcert.com</span>

                        </span>

                    </div>
                </Form>


            </div>
        </div>
    );
}

export default Login;