import { useForm, Form, FormSubmitHandler, FieldValues } from "react-hook-form"
import React, { useState, useRef, useEffect } from "react";
import { getCookie } from "../utils/cookie"
import { useNavigate } from "react-router-dom";

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const InquiryTypeModal = (props: any) => {

  const sel_lang = "KR"
  const [detail, setDetail] = useState<List>();
  const [langList, setLangList] = useState<List>();

  const {
    register,
    control,
    setValue
    // formState: { errors },
  } = useForm<FieldValues>()


  const onSubmitForm: FormSubmitHandler<FieldValues> = async ({
    formData,
    data,
  }) => {
    run_execute(formData)
  };

  const getLangList = async () => {
    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setLangList(data);

      

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const getTypeDetail = async (id: any) => {
    try {
      const data = await (await fetch(`https://playcon.net/api/admin/inquiry/type/detail/${sel_lang}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setDetail(data)

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const closeModal = () => {
    document.body.style.overflow = "unset";
    props.setShowModal(false)
    if (langList) {
      const langs = Object.keys(langList?.data)
      for (let i = 0; i < langs.length; i++) {
        setValue("inquiry_type_name_" + langs[i], "")
      }
    }
  }

  const run_execute = async (fdData: FormData) => {

  

      if(props.inquiryId > 0){

        try {
          fdData.append("inquiry_type_id", props.inquiryId)
    
          const data = await (await fetch('https://playcon.net/api/admin/inquiry/type/update', {
            method: 'PUT',
            body: fdData,
            headers: {
              Authorization: "Bearer " + getCookie("accessToken"),
            },
          })).json();
    
          if(data.message === "Update Success"){
            alert("수정 완료");
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
        } finally {
          console.log('finish')
        }
    
      }else{
        try {
          const data = await (await fetch('https://playcon.net/api/admin/inquiry/type/write', {
            method: 'POST',
            body: fdData,
            headers: {
              Authorization: "Bearer " + getCookie("accessToken"),
            },
          })).json();
    
          if(data.message === "Insert Success"){
            alert("등록 완료");
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
        } finally {
          console.log('finish')
        }
    
      }

  }

  useEffect(() => {


    if (props.showModal) {

      getLangList();

      if (props.modify) {
        getTypeDetail(props.inquiryId);

      } else {

      }

    } else {

    }
  }, [props.showModal])

  useEffect(() => {

    // console.log(detail?.data)

    if (detail) {
      const names = Object.keys(detail?.data)

      for (let i = 0; i < names.length; i++) {
        setValue(names[i], detail?.data[names[i]][0].inquiry_type_name)
      }
    }


  }, [detail])



  return (
    <>
      {props.showModal ? (
        <>
          <Form
            control={control}
            className=""
            onSubmit={onSubmitForm}
          >
            <div
              className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none h-auto" onClick={(e) => {
                if (e.target === e.currentTarget) closeModal()
              }}>
              <div className="relative my-6 mx-auto w-[489px] h-[700px] overflow-y-auto border-0 shadow no-scrollbar bg-white rounded ">
                {/* <div className="relative w-auto my-6 mx-auto max-w-3xl"> */}
                {/*content*/}

                <div className=" relative flex flex-col w-full h-full outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between rounded-t pl-[47px] pr-[35px] pt-[40px] pb-[18px]">
                    <h3 className="text-[25px] font-bold text-[#313a46]">
                      문의유형 {props.modify ? (<>수정</>) : (<>등록</>)}
                    </h3>
                    <button
                      className="p-1 ml-auto  border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => props.setShowModal(false)}
                    >
                      <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">

                      </span>
                    </button>
                  </div>

                  {/*body*/}

                  <div className="relative pl-[47px] pr-[35px] flex-auto">
                    {typeof langList !== 'undefined' ? (
                      <>
                        {Object.keys(langList.data).map((NO, index) => (<div key={index}>

                          <p className="text-blueGray-500 text-lg leading-relaxed relative mt-[10px] ">
                            <span className="text-[14px]">문의유형명({langList.data[Object.keys(langList.data)[index]]})</span>

                            <input type="text" className="border border-[#dddddd] rounded block w-full h-[38px] px-[10px] text-[14px] mt-[10px]" {...register("inquiry_type_name_" + Object.keys(langList.data)[index], { required: true, })}></input>
                          </p>


                        </div>))}
                      </>
                    ) : null}
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-center py-[40px] border-blueGray-200 rounded-b">
                    {props.modify ? (
                      <button
                        className="w-[150px] h-[50px] rounded-[4px] text-[14px] text-[#ffffff] bg-[#313a46]"
                        type="submit"
                      >
                        수정하기
                      </button>
                    ) : (
                      <button
                        className="w-[150px] h-[50px] rounded-[4px] text-[14px] text-[#ffffff] bg-[#313a46]"
                        type="submit"
                      >
                        등록하기
                      </button>
                    )}


                  </div>
                </div>
              </div>

            </div>
          </Form>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null
      }
    </>
  );
}

export default InquiryTypeModal;
