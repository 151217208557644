import React, { useRef, useEffect, useState } from "react";

import Sidebar from '../../components/Sidebar'
import { getCookie } from "../../utils/cookie"
import arrow_left from '../../img/arrow_left.png'
import arrow_right from '../../img/arrow_right.png'
import search from '../../img/search-icon.png'

import edit_btn from '../../img/edit.png'
import delete_btn from '../../img/delete.png'
import InquiryTypeModal from '../../components/InquiryTypeModal'

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const InquiryType = () => {
  const sel_lang = "KR"
  const ref_CH = useRef<HTMLInputElement>(null);
  const [cHeight, setCheight] = useState('');
  const [typelist, setTypeList] = useState<List>();


  let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const getTypeList = async (p: any) => {
    try {
      const data = await (await fetch(`https://playcon.net/api/admin/inquiry/type/list/all/${sel_lang}/${p}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setTypeList(data)

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }


  useEffect(() => {

    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')
    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')
    }

    getTypeList(0);

  }, [])


  const [showModal, setShowModal] = useState(false);
  const [inquiryId, setInquiryId] = useState(0);
  const [modify, setModify] = useState(true);


  const showDetail = (isShow: boolean | ((prevState: boolean) => boolean),inquiryId: React.SetStateAction<number>, isModify: boolean | ((prevState: boolean) => boolean)) => {
    document.body.style.overflow = "hidden";
    setShowModal(isShow);
    setInquiryId(inquiryId)
    setModify(isModify)

  }

  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>문의 유형 리스트</p>

          <div className={cHeight}>


            <div className="p-[35px]">
              <div className="">
                <div className=''>
                  <label className='relative hidden'>
                    <input type="text" className='border border-solid border-[#313a46] pl-[14px] pr-[46px] py-[20px] w-[410px] h-[10px] rounded text-[14px] placeholder:text-[#313a46]' placeholder='제목으로 검색 가능' />
                    <img src={search} alt="" className='absolute top-[4px] left-[374px]' />
                  </label>
                  <button type="button" className='w-[150px] h-[44px] bg-[#313a46] rounded-[4px] text-[14px] text-[#ffffff]' onClick={(e)=>{showDetail(true,0,false)}}>+ 등록</button>
                </div>
                <div className='float-right pb-[15px] relative invisible'>

                  <button className='w-[36px] h-[36px] text-[12px] border border-[#dddddd]'>
                    ▲
                  </button>
                  <button className='w-[36px] h-[36px] ml-[6px] text-[12px] border border-[#dddddd]'>
                    ▼
                  </button>
                  <div className='absolute left-[78px] top-[-116px] '>
                    {/* hidden 추가 하여 toggle */}
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-[#dddddd]'>
                      최신순 ▼
                    </button>
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                      최신순
                    </button>
                    <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                      등록일순
                    </button>
                  </div>
                  <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border border-[#dddddd]'>
                    최신순 ▼
                  </button>

                  


                </div>
                <table className="table-auto w-full -[15px]">
                  <thead>
                    <tr>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] rounded text-[#000]"><input type='checkbox' /></th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] rounded text-[#000]">번호</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">문의유형명</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] text-center font-bold text-[14px] text-[#000]">수정</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] text-center font-bold text-[14px] rounded text-[#000]">삭제</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-slate-800">

                    {typeof typelist !== 'undefined' ? (
                      <>
                 
                        {Object.keys(typelist.data).map((NO, index) => (<>
                          <tr>
                            <td className="py-[5px] px-[10px] text-[14px] h-[68px] align-middle border-b-[2px] border-solid border-[#dddddd]"><input type='checkbox' /></td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{typelist.data[index].inquiry_type_id}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{typelist.data[index].inquiry_type_name}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] text-center border-solid border-[#dddddd]"onClick={(e)=>{showDetail(true,typelist.data[index].inquiry_type_id,true)}}><center><img src={edit_btn} alt="" /></center></td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] text-center border-solid border-[#dddddd]"><center><img src={delete_btn} alt="" /></center></td>
                          </tr>
                        </>))}
                      </>
                    ) : null} 

                  </tbody>
                </table>
                <div className='w-full flex flex-nowrap justify-center mt-[20px] hidden'>

                  <ul>
                    <li className='inline-block mr-[42px]'>
                      <img src={arrow_left} alt=""></img>
                    </li>
                    <li className='inline-block w-[28px] h-[28px] bg-[#313a46] text-[#ffffff] text-center'>
                      <p>1</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>2</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>3</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>4</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>5</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>6</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>7</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>8</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>9</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>10</p>
                    </li>
                    <li className='inline-block ml-[42px]'>
                      <img src={arrow_right} alt=""></img>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <InquiryTypeModal setShowModal={setShowModal} showModal={showModal} inquiryId={inquiryId} modify={modify} />
    </>
  );
}
export default InquiryType;

