import { useForm, Form, FormSubmitHandler, FieldValues } from "react-hook-form"
import React, { useState, useRef, useEffect } from "react";
import { getCookie } from "../utils/cookie"
import { useNavigate } from "react-router-dom";


interface List {
    code: string;
    status: number;
    success: boolean;
    message: string;
    data: any;
}

const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const getToday = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);

    return year + "-" + month;
}

const HistoryModal = (props: any) => {


    const navigate = useNavigate();
    const refPic = useRef<HTMLInputElement>(null);
    const refMonth = useRef<HTMLInputElement>(null);
    const [detail, setDetail] = useState<List>();


    const {

        register,
        control,
        setValue
        // formState: { errors },
    } = useForm<FieldValues>()

    const run_execute = async (fdData: FormData) => {

        if (!props.modify) {
            try {
                fdData.append("file_0", props.fileList)

                const data = await (await fetch('https://playcon.net/api/admin/history/write', {
                    method: 'POST',
                    body: fdData,
                    headers: {
                        Authorization: "Bearer " + getCookie("accessToken"),
                    },
                })).json();

                if(data.message === "Insert Success"){
                    alert("등록완료")
                    window.location.replace('/admin/history') 
                  }else{
                    alert("작성중 오류발생")
                  }


                console.log(data)
            } catch (error) {
                console.error(error);
            } finally {
                console.log('finish')
            }
        } else {
            try {
                if (props.fileList) fdData.append("file_0", props.fileList)
                fdData.append("history_id", props.historyId)
                const data = await (await fetch('https://playcon.net/api/admin/history/update', {
                    method: 'PUT',
                    body: fdData,
                    headers: {
                        Authorization: "Bearer " + getCookie("accessToken"),
                    },
                })).json();

                if(data.message === "Insert Success"){
                    alert("수정완료")
                    window.location.replace('/admin/history')
                }
            } catch (error) {
                console.error(error);
            } finally {
                console.log('finish')
            }
        }

    }


    const uptDate = (e: any) => {
        props.setSelDate(e.target.value);
    }

    const openMonth = () => {
        refMonth?.current?.showPicker();
    }


    const openSelFile = () => {
        refPic?.current?.click();
    }

    const selectedFile = async (e: any) => {
        props.setFileList(e?.target?.files[0])
        const base64 = await toBase64(e?.target?.files[0]);
        props.setImgString(base64 as string)
        props.setImgName(e?.target?.files[0].name)
    }




    const onSubmitFindForm: FormSubmitHandler<FieldValues> = async ({
        formData,
        data,
    }) => {
        run_execute(formData)
    };


const closeModal = ()=>{
    if(props.write === "Y"){
        navigate("/admin/history")
    }else{
        props.setShowModal(false);
    }
   

     document.body.style.overflow = "unset";
}




    useEffect(() => {
        if (!props.showModal && props?.langList) {
            const langs = Object.keys(props?.langList?.data);


            if (props.modify) {


                setValue("is_active", "");

            } else {
                setValue("is_active", "Y");
                for (let i = 0; i < langs.length; i++) {
                    const nation = Object.keys(props.langList.data)[i];
                    setValue("link_" + nation, "");
                    setValue("content_" + nation, "");

                }
                setValue("history_ymd", "");
            }


            props.setHistoryId(0)
            props.setSelDate("")
        }

    }, [props.showModal])


    useEffect(() => {
        const run = async () => {

            if (props.historyId > 0) {

                try {

                    const data = await (await fetch(`https://playcon.net/api/admin/history/detail/${props.historyId}`, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + getCookie("accessToken"),
                        },
                    })).json();

                    setDetail(data)

                    const langs = Object.keys(props.langList.data)



                    for (var i = 0; i < langs.length; i++) {

                        setValue("content_" + langs[i], data.data["detail_" + langs[i]][0].content)
                        setValue("link_" + langs[i], data.data["detail_" + langs[i]][0].link)
                        //console.log( data.data["detail_KR"][0].link)
                    }
                    setValue("history_ymd", data.data["detail_KR"][0].history_ymd.slice(0, 7))
                    props.setSelDate(data.data["detail_KR"][0].history_ymd.slice(0, 7))
                    setValue("is_active", data.data["detail_KR"][0].is_active)

                } catch (error) {
                    console.error(error);
                } finally {
                    console.log('finish')
                }
            } else {

            }
        }
        run();

    }, [props.historyId])


    return (
        <>
            {props.showModal ? (

                <Form className="" control={control} onSubmit={onSubmitFindForm}>
                    <div
                        className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none h-auto" onClick={(e) => {
                            if (e.target === e.currentTarget) { closeModal(); }
                        }}>
                        <div className="relative my-6 mx-auto w-[489px] h-[700px] overflow-y-auto border-0 shadow no-scrollbar bg-white rounded ">
                            {/* <div className="relative w-auto my-6 mx-auto max-w-3xl"> */}
                            {/*content*/}

                            <div className=" relative flex flex-col w-full h-full outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between rounded-t pl-[47px] pr-[35px] pt-[40px] pb-[28px]">
                                    <h3 className="text-[25px] font-bold text-[#313a46]">
                                        {props.modify ? (
                                            <>
                                                히스토리 수정
                                            </>
                                        ) : (
                                            <>
                                                히스토리 등록
                                            </>)}

                                    </h3>
                                    <button
                                        className="p-1 ml-auto  border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => props.setShowModal(false)}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">

                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                {/* {typeof detail !== 'undefined' ? (
                                    <>
                                        {Object.keys(detail.data)}
                                        {detail?.data["detail_KR"][0].file_id}
                                    </>

                                ) : null} */}
                                <div className="relative pl-[47px] pr-[35px] flex-auto">

                                    {props.modify && typeof detail !== 'undefined' ? (

                                        <div className="text-blueGray-500 text-lg leading-relaxed relative">
                                            <span className="text-[14px]">썸네일</span>

                                            {props.fileList?.name ? (<>

                                                <img src={props.imgString} alt="" className="pt-[10px] w-[94px]" />
                                            </>) : (<>
                                                <img src={`https://playcon.net/api/common/file/image/${detail?.data["detail_KR"][0].file_id}`} alt="" className="pt-[10px] w-[94px]" />
                                            </>)}

                                            <input ref={refPic} type="file" className="absolute left-[0px] top-[0px] -z-10" onChange={selectedFile}></input>

                                            <div className="absolute left-[104px] top-[60px]">
                                                <p className="text-[14px] text-[#cccccc]">JPG 또는 PNG의 600 x 600 px 사이즈 권장.</p>
                                                <p className="text-[14px] text-[#cccccc]">파일크기는 5mb를 권장합니다.</p>
                                            </div>
                                            <div className="absolute left-[104px] top-[110px]">
                                                <button type="button" className="w-[77px] h-[26px] bg-[#efefef] border text-[#222222] text-[12px] border-[#767676] left-[120px] top-[0px]" onClick={(e) => { openSelFile() }} >파일 선택</button>
                                                {props.imgName ? (<>
                                                    <span className="text-[12px] text-[#0066ff] ml-[5px]">{props.imgName}</span>
                                                </>) : (<>
                                                    <span className="text-[12px] text-[#0066ff] ml-[5px]">{detail?.data["detail_KR"][0].file_ori_name}</span>
                                                </>)}

                                            </div>
                                        </div>

                                    ) : (
                                        <>
                                            <div className="text-blueGray-500 text-lg leading-relaxed">
                                                <span className="text-[14px]">썸네일</span>
                                                {props.fileList?.name ? (<>
                                                    {/* 첨부파일 이미지 보이게 하려면 주석해제 */}
                                                    {/* <img src={props.imgString} alt="" className="w-[94px] h-[100px]" /> */}
                                                </>) : (null)}
                                                <input ref={refPic} type="file" className="absolute left-[0px] top-[0px] -z-10" onChange={selectedFile}></input>
                                                <div className="mt-[10px]">
                                                    <p className="text-[14px] text-[#cccccc]">JPG 또는 PNG의 600 x 600 px 사이즈 권장.</p>
                                                    <p className="text-[14px] text-[#cccccc]">파일크기는 5mb를 권장합니다.</p>
                                                </div>
                                                <div className="border border-solid pb-[4px] px-[4px] mt-[5px] border-[#dddddd] rounded-[4px]  ">
                                                    <button type="button" className="w-[77px] h-[26px] bg-[#efefef] border text-[#222222] text-[12px] border-[#767676] left-[120px] top-[0px]" onClick={(e) => { openSelFile() }}>파일 선택</button>
                                                    {props.fileList?.name ? (<>
                                                        <span className="text-[12px] text-[#999999] ml-[5px]">{props.fileList?.name}</span>
                                                    </>) : (<>
                                                        <span className="text-[12px] text-[#999999] ml-[5px]">파일을 선택해주세요</span>

                                                    </>)}


                                                </div>
                                            </div>

                                        </>
                                    )}
                                    <div className="text-blueGray-500 text-lg leading-relaxed mt-[30px]">
                                        <div className="text-blueGray-500 text-lg leading-relaxed relative mt-[30px] ">
                                            <span className="text-[14px]">히스토리 날짜</span>
                                            <div className="block">
                                                {props.modify ? (<>


                                                    <input type="text" className="border border-[#dddddd] rounded block w-full h-[38px] px-[10px] text-[14px] mt-[10px] hover:cursor-pointer" onClick={(e) => { openMonth() }} value={props.selDate} {...register("history_ymd", {
                                                        required: true,
                                                    })} readOnly></input>


                                                </>) : (<>
                                                    <input type="text" className="border border-[#dddddd] rounded block w-full h-[38px] px-[10px] text-[14px] mt-[10px] hover:cursor-pointer" onClick={(e) => { openMonth() }} value={props.selDate} {...register("history_ymd", {
                                                        required: true,
                                                    })} readOnly></input>
                                                </>)}


                                                <input type="month" className="w-[0px] h-[0px] absolute t-[0px]" ref={refMonth} onChange={(e: any) => { uptDate(e) }}></input>
                                            </div>

                                        </div>

                                    </div>
                                    {/* {typeof props.langList !== 'undefined' ? <p>{Object.keys(props.langList.data)[0]} {props.langList.data[Object.keys(props.langList.data)[0]]}</p> : null} */}

                                    {typeof props.langList !== 'undefined' ? (
                                        <>
                                            {Object.keys(props.langList.data).map((NO, index) => (<div key={index}>
                                                <p className="text-blueGray-500 text-lg leading-relaxed relative mt-[30px]">
                                                    <span className="text-[14px]">내용({props.langList.data[Object.keys(props.langList.data)[index]]})</span>

                                                    <input type="text" className="border border-[#dddddd] rounded block w-full h-[38px] px-[10px] text-[14px] mt-[10px]" {...register("content_" + Object.keys(props.langList.data)[index], { required: true })} ></input>
                                                </p>
                                                <p className="text-blueGray-500 text-lg leading-relaxed relative mt-[30px]">
                                                    <span className="text-[14px]">링크</span>
                                                    <input type="text" className="border border-[#dddddd] rounded block w-full h-[38px] px-[10px] text-[14px] mt-[10px]" {...register("link_" + Object.keys(props.langList.data)[index], { required: true })} ></input>
                                                </p>
                                            </div>))}
                                        </>
                                    ) : null}


                                    <div className="text-blueGray-500 text-lg leading-relaxed relative mt-[30px]">
                                        <span className="text-[14px]">활성화 여부</span>
                                        <div className="block">


                                            {props.modify ? (
                                                <>
                                                    <label className="">
                                                        <input type="radio" {...register("is_active", { required: false })} value={"Y"}></input>
                                                        <span className="text-[14px] ml-[5px]">활성화</span>
                                                    </label>
                                                    <label className="ml-[20px]">
                                                        <input type="radio" {...register("is_active", { required: false })} value={"N"}></input>
                                                        <span className="text-[14px] ml-[5px]">비활성화</span>
                                                    </label>
                                                </>
                                            ) : (
                                                <>
                                                    <label className="">
                                                        <input type="radio" {...register("is_active", { required: false })} value={"Y"} checked></input>
                                                        <span className="text-[14px] ml-[5px]">활성화</span>
                                                    </label>
                                                    <label className="ml-[20px]">
                                                        <input type="radio" {...register("is_active", { required: false })} value={"N"} disabled></input>
                                                        <span className="text-[14px] ml-[5px]">비활성화</span>
                                                    </label>
                                                </>)}
                                        </div>


                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-center py-[40px] border-blueGray-200 rounded-b">
                                    {props.modify ? (
                                        <button
                                            className="w-[150px] h-[50px] rounded-[4px] text-[14px] text-[#ffffff] bg-[#313a46]"
                                            type="submit"
                                        >
                                            수정하기
                                        </button>
                                    ) : (
                                        <button
                                            className="w-[150px] h-[50px] rounded-[4px] text-[14px] text-[#ffffff] bg-[#313a46]"
                                            type="submit"
                                        >
                                            등록하기
                                        </button>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </Form>
            ) : null}
        </>
    );
}

export default HistoryModal;
