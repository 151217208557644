import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Home from './pages/Home';
import Login from './pages/Common/Login'
import Main from './pages/Common/Main'
import History from './pages/History/History'
import HistoryWrite from './pages/History/HistoryWrite'
import Intprp from './pages/Ip/Intprp'
import IntprpWrite from './pages/Ip/IntprpWrite'
import IntprpModify from './pages/Ip/IntprpModify'
import Notice from './pages/Notice/Notice'
import NoticeWrite from './pages/Notice/NoticeWrite'
import NoticeModify from './pages/Notice/NoticeModify'
import Inquiry from './pages/Inquiry/Inquiry'
import InquiryType from './pages/Inquiry/InquiryType'
import InquiryDetail from './pages/Inquiry/InquiryDetail'
import FamilySite from './pages/Familysite/FamilySite'


const App = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin/main' element={<Main />} />
        <Route path='/admin/history' element={<History />} />
        <Route path='/admin/history/write' element={<HistoryWrite />} />

        <Route path='/admin/ip' element={<Intprp />} />
        <Route path='/admin/ip/write' element={<IntprpWrite />} />
        <Route path='/admin/ip/modify/:id' element={<IntprpModify />} />
        
        <Route path='/admin/notice' element={<Notice />} />
        <Route path='/admin/notice/write' element={<NoticeWrite />} />
        <Route path='/admin/notice/modify/:id' element={<NoticeModify />} />

        <Route path='/admin/inquiry' element={<Inquiry />} />
        <Route path='/admin/inquiry/detail/:id' element={<InquiryDetail />} />
        <Route path='/admin/inquiry/type' element={<InquiryType />} />
        
        <Route path='/admin/familysite' element={<FamilySite />} />
        
        

        {/* <Route path='/pageA' element={<PageA />} />
        <Route path='/pageB' element={<PageB />} />
        <Route path='/pageC' element={<PageC />} /> */}
      </Route>
    </Routes>
  );
}
export default App;