
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookie"
import Sidebar from '../../components/Sidebar'
import IconCalendar from '../../img/icon-calendar.png'
import { useForm, Form, FormSubmitHandler, FieldValues } from "react-hook-form"
import AddUrl from '../../img/addurl.png'
import DelUrl from '../../img/delurl.png'

interface Result {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

interface linklist {
  data: [];
}


const getToday = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
}

const IntprpDetail = () => {

  const navigate = useNavigate();

  const [langList, setLangList] = useState<Result>();

  const ref_selFile = useRef<HTMLInputElement>(null);
  const refPic = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<(File | null)[]>([]);

  const ref_CH = useRef<HTMLInputElement>(null);
  const [cHeight, setCheight] = useState('');

  const [selDate, setSelDate] = useState("");
  const clk_date = useRef<HTMLInputElement>(null);

  const [platFormList, setPlatFormList] = useState<{ [key: string]: any }>({});

  const [imgString, setImgString] = useState("");
  const [imgName, setImgName] = useState("");

  const exec_write = async (fdData: FormData) => {


    try {

      const langs = Object.keys(platFormList);

      for (let i = 0; i < langs.length; i++) {
        fdData.append("platform_cnt_" + langs[i], platFormList[langs[i]].length)
      }
      
      fdData.append("file_0",fileList as unknown as File)
  
 
      const data = await (await fetch('https://playcon.net/api/admin/ip/write', {
        method: 'POST',
        body: fdData,
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();


      if(data.message === "Insert Success"){
        alert("등록완료")
        navigate("/admin/ip");
      }else{
        alert("작성중 오류발생")
      }




    } catch (error) {
      console.error(error);


    } finally {
      console.log('finish')
    }
  }

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useForm<FieldValues>();
  const onSubmitForm: FormSubmitHandler<FieldValues> = ({ data, formData }) => {


    exec_write(formData)
  };

  const getLangList = async () => {
    const platFormli: { [key: string]: any } = {};
    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();



      setLangList(data);

      const dtlang = Object.keys(data.data)
      const newarr = data.data;

      for (let i = 0; i < dtlang.length; i++) {
        platFormli[dtlang[i]] = new Array()
        platFormli[dtlang[i]].push("mt-[0px]")
      }


      setPlatFormList(platFormli)


    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }



  const uptDate = (e: any) => {
    setSelDate(e.target.value);
  }

  const openDatePicker = () => {
    clk_date?.current?.showPicker();
  }





  useEffect(() => {


    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')
    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')
    }
  }, [])



  useEffect(() => {
    getLangList();




  }, [])

  const delPlatForm = async (str: any, idx: number) => {

    let platFormli: { [key: string]: any } = {};

    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      const dtlang = Object.keys(data.data)


      for (let i = 0; i < dtlang.length; i++) {
        platFormli[dtlang[i]] = [...platFormList[dtlang[i]]]
      }

      const allarr = platFormList[str].length-1
      const selarr = allarr - idx;


      if(idx == 0){
        for(let i=0; i<selarr; i++){
          console.log("for",i)
          setValue("platform_name_" + str + "_" + (i ), getValues("platform_name_" + str + "_" + (i + 1)))
          setValue("platform_link_" + str + "_" + (i ), getValues("platform_link_" + str + "_" + (i + 1)))
        }

      }else{
        if (selarr === 0) {

          setValue("platform_name_" + str + "_" + (idx - 1), getValues("platform_name_" + str + "_" + (idx - 1)))
          setValue("platform_link_" + str + "_" + (idx - 1), getValues("platform_link_" + str + "_" + (idx - 1)))
          setValue("platform_name_" + str + "_" + idx, "")
          setValue("platform_link_" + str + "_" + idx, "")
        }else{
  
          for(let i=1; i<=selarr; i++){
            console.log("for",i)
            setValue("platform_name_" + str + "_" + (i ), getValues("platform_name_" + str + "_" + (i + 1)))
            setValue("platform_link_" + str + "_" + (i ), getValues("platform_link_" + str + "_" + (i + 1)))
          }
  
        }
      }

  



      platFormli[str].splice(idx, 1);

      setPlatFormList(platFormli)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }


  }
  const addPlatForm = async (str: any) => {

    let platFormli: { [key: string]: any } = {};




    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      const dtlang = Object.keys(data.data)


      for (let i = 0; i < dtlang.length; i++) {
        platFormli[dtlang[i]] = [...platFormList[dtlang[i]]]

      }
      platFormli[str].push("mt-[5px]")
      setPlatFormList(platFormli)
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }


  }

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const openSelFile = () => {
    refPic?.current?.click();
  }

  const selectedFile = async (e: any) => {
    setFileList(e?.target?.files[0])
    const base64 = await toBase64(e?.target?.files[0]);
    setImgString(base64 as string)
    setImgName(e?.target?.files[0].name)
  }


  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>IP리스트 등록페이지</p>
          <Form
            control={control}
            className=""
            onSubmit={onSubmitForm}>

            <div className="bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]">
              <div className='p-[35px]'>

                {/* 이미지 */}
                <div className="h-[201px] flex border border-solid rounded-t-[8px] border-[#dddddd]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">이미지</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">

                    <div className="w-[150px] h-[180px] border border-solid border-[#dddddd]">
                      {imgString ? (<><img src={imgString} alt="" className="w-[150px] h-[180px]"></img></>) : (null)}
                    </div>


                    <input type="file" className="w-[0px] h-[0px] absolute top-[0px] left-[0px]" ref={refPic} onChange={selectedFile} />
                    {imgName ? (<><p className="absolute top-[65px] left-[170px] text-[12px] text-[#2760e5]" >{imgName}</p></>) : (null)}
                    <button type="button" className="w-[150px] h-[50px] bg-[#313a46] rounded-[4px] text-[#ffffff] text-[14px] absolute top-[90px] left-[170px]" onClick={openSelFile}>이미지 업로드</button>
                    <div className="absolute top-[148px] left-[170px]">
                      <p className="text-[14px] text-[#cccccc]">JPG 또는 PNG의 520 x 728 px 사이즈 권장.</p>
                      <p className="text-[14px] text-[#cccccc]">파일크기는 5mb를 권장합니다.</p>
                    </div>
                  </div>
                </div>
                {/* 제목 */}

                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>
                      <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                        <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">제목({langList.data[Object.keys(langList.data)[index]]})</p>
                        </div>
                        <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                          <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]" {...register("title_" + Object.keys(langList.data)[index], {
                            required: true,
                          })}
                            defaultValue={""}></input>
                        </div>
                      </div>
                    </div>))}
                  </>
                ) : null}


                {/* 론칭일 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">론칭일 선택</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <label className="relative">
                      <img src={IconCalendar} alt="" className="absolute top-[11.5px] left-[10px] " />
                      <input type="text" className="border border-[#dddddd] rounded block w-[202px] h-[38px] pl-[34px] pr-[10px] text-[14px] text-[#cccccc]" onClick={() => { openDatePicker(); }} value={selDate} {...register("launch_ymd", {
                        required: true,
                      })} readOnly></input>
                    </label>
                    <input type="date" className="absolute top-[0px] left-[0px] block w-[0px] h-[0px] px-[10px] text-[14px]" ref={clk_date} onChange={(e: any) => { uptDate(e) }}></input>
                    <div className="absolute  top-[16px] left-[246px]">

                      <label className=" ">
                        <input type="radio" {...register("launch_status", {
                          required: true,
                        })}
                          defaultValue={"L"}></input>
                        <span className="text-[14px] ml-[5px]">론칭</span>
                      </label>

                      <label className="ml-[40px]" >
                        <input type="radio" {...register("launch_status", {
                          required: true,
                        })}
                          defaultValue={"S"} ></input>
                        <span className="text-[14px] ml-[5px]">예정</span>
                      </label>

                    </div>
                  </div>
                </div>
                {/* 시놉시스 */}
                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>
                      <div className="h-[230px] flex border border-solid border-[#dddddd] border-t-[0px]">
                        <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">시놉시스 또는 줄거리 영역<br />({langList.data[Object.keys(langList.data)[index]]}) </p>
                        </div>
                        <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                          <textarea className="border border-[#dddddd] rounded block w-[496px] h-full p-[10px] text-[14px]" {...register("synopsys_" + Object.keys(langList.data)[index], {
                            required: true,
                          })}></textarea>

                        </div>
                      </div>
                    </div>))}
                  </>
                ) : null}


                {/* 원작링크 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">원작링크</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                    <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]" {...register("origin_link", {
                      required: true,
                    })}></input>
                  </div>
                </div>
                {/* 작가(텍스트) */}
                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>

                      <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                        <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">작가(텍스트)({langList.data[Object.keys(langList.data)[index]]})</p>
                        </div>
                        <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                          <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]"  {...register("author_name_" + Object.keys(langList.data)[index], {
                            required: true,
                          })}></input>
                        </div>
                      </div>
                    </div>))}
                  </>
                ) : null}
                {/* 키워드(한국어) */}
                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>
                      <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px]">
                        <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">키워드({langList.data[Object.keys(langList.data)[index]]})</p>
                        </div>
                        <div className="h-full w-[calc(100%-325px)] p-[10px] relative">
                          <input type="text" className="border border-[#dddddd] rounded block w-[496px] h-[38px] px-[10px] text-[14px]" {...register("keyword_" + Object.keys(langList.data)[index], {
                            required: true,
                          })}></input>
                        </div>
                      </div>
                    </div>))}
                  </>
                ) : null}
                {/* 플랫폼 링크(한국어) */}
                {typeof langList !== 'undefined' ? (
                  <>
                    {Object.keys(langList.data).map((NO, index) => (<div key={index}>
                      <div className="h-auto flex border border-solid border-[#dddddd] border-t-[0px] relative ">
                        <div className="w-[325px] border-r border-solid h-auto flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                          <p className="text-[14px] text-[#000000] font-bold">플랫폼 링크({langList.data[Object.keys(langList.data)[index]]})</p>
                        </div>
                        <div className="h-[100%] w-[calc(100%-325px)] p-[10px] relative">
                          {/* 
                       
                        {Object.keys(platFormList).map((NO:any,index:any) => (<div key={index}>
                          d
                 
                          </div>))} */}

                          {platFormList[Object.keys(langList.data)[index]].map((NO: any, idx: any) => (<div key={idx}>
                            <div className={`block ${platFormList[Object.keys(langList.data)[index]][idx]}`}>

                              <input type="text" className="border border-[#dddddd] rounded inline w-[174px] h-[38px] px-[10px] text-[14px]" {...register("platform_name_" + Object.keys(langList.data)[index] + "_" + idx, {
                                required: false,
                              })} ></input>
                              <input type="text" className="border border-[#dddddd] rounded inline ml-[5px] w-[274px] h-[38px] px-[10px] text-[14px]" {...register("platform_link_" + Object.keys(langList.data)[index] + "_" + idx, {
                                required: false,
                              })} ></input>
                              {
                                idx > 0 ? (<>

                                  {(platFormList[Object.keys(langList.data)[index]].length - idx) == 1 ? (<>
                                    <img src={DelUrl} className="inline absolute t-[0px] ml-[5px]" alt="" onClick={(e) => { delPlatForm(Object.keys(langList.data)[index], idx) }} />
                                    <img src={AddUrl} className="inline absolute t-[0px] ml-[48px]" alt="" onClick={(e) => { addPlatForm(Object.keys(langList.data)[index]) }} />
                                  </>) : (<>

                                    <img src={DelUrl} className="inline absolute t-[0px] ml-[5px]" alt="" onClick={(e) => { delPlatForm(Object.keys(langList.data)[index], idx) }} />
                                  </>)}
                                </>) : (<>
                                  {platFormList[Object.keys(langList.data)[index]].length > 1 ? (<>
                                    <img src={DelUrl} className="inline absolute t-[0px] ml-[5px]" alt="" onClick={(e) => { delPlatForm(Object.keys(langList.data)[index], idx) }} />
                                  </>) : (<>
                                    <img src={AddUrl} className="inline absolute t-[0px] ml-[5px]" alt="" onClick={(e) => { addPlatForm(Object.keys(langList.data)[index]) }} />
                                  </>)}

                                </>)
                              }


                            </div>
                          </div>))}

                        </div>

                      </div>
                    </div>))}
                  </>
                ) : null}

                {/* 활성화 여부 */}
                <div className="h-[60px] flex border border-solid border-[#dddddd] border-t-[0px] rounded-b">
                  <div className="w-[325px] border-r border-solid h-full flex items-center justify-start px-[30px] border-[#dddddd] bg-[#eef2f7]">
                    <p className="text-[14px] text-[#000000] font-bold">활성화 여부</p>
                  </div>
                  <div className="h-full w-[calc(100%-325px)] flex items-center justify-start ">

                    <label className="ml-[10px]">
                      <input type="radio" {...register("is_active", {
                        required: true,
                      })}
                        defaultValue={"Y"}></input>
                      <span className="text-[14px] ml-[5px]">활성화</span>
                    </label>

                    <label className="ml-[40px]" >
                      <input type="radio" {...register("is_active", {
                        required: true,
                      })}
                        defaultValue={"N"} ></input>
                      <span className="text-[14px] ml-[5px]">비활성화</span>
                    </label>
                  </div>
                </div>

                <div className="flex justify-end mt-[50px]">
                  <button type="submit" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff]">등록</button>
                  <button type="button" className="w-[150px] h-[50px] rounded-[8px] bg-[#313a46] text-[14px] text-[#ffffff] ml-[10px]">취소</button>
                </div>
              </div>
            </div>

          </Form>
        </div>
      </div>
    </>
  );
}
export default IntprpDetail;