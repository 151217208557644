import React from "react";

import Sidebar from '../../components/Sidebar'

import main_logo from '../../img/logo-playcon-main.png'

const Main = () => {



  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-screen  bg-[#eef2f7] flex justify-center items-center'>
          {/* <div className='bg-[#ffffff] h-[100vh] flex justify-center content-center'>
            <div className='p-[35px] h-full flex content-center '>
              <div className="flex content-center">
                <img src={main_logo} alt="" className="w-[844px] h-[194px] flex content-center "></img>
              </div>
              
            </div>
          </div> */}
          <img src={main_logo} alt="" className="w-[844px] h-[194px] flex content-center "></img>
        </div>
      </div>
  
    </>
  );
}
export default Main;