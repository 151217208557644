import React, { useState, useEffect, useRef } from "react";

import { getCookie } from "../../utils/cookie"
import Sidebar from '../../components/Sidebar'
import thumb from '../../img/thumb.png'
import arrow_left from '../../img/arrow_left.png'
import arrow_right from '../../img/arrow_right.png'
import search from '../../img/search-icon.png'

import edit_btn from '../../img/edit.png'
import delete_btn from '../../img/delete.png'

import { useSearchParams,Link } from 'react-router-dom';


import HistoryModal from '../../components/HistoryModal'
import ipip from '../../img/ipip.png';

interface List {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const History = () => {
  const sel_lang = "KR";
  const ref_CH = useRef<HTMLInputElement>(null);
  const [cHeight, setCheight] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const [langList, setLangList] = useState<List>();
  const [historylist, setHistoryList] = useState<List>();
  const [showModal, setShowModal] = useState(false);
  const [historyId, setHistoryId] = useState(0);
  const [modify, setModify] = useState(true);
  const [listOrder, setListOrder] = useState(false);
  const [selDate, setSelDate] = useState("");
  const [fileList, setFileList] = useState<(File | null)>();
  const [imgString, setImgString] = useState("");
  const [imgName, setImgName] = useState("");

  let arr = [0];

  useEffect(() => {
    getLangList();
  }, [])

  useEffect(() => {
    console.log(searchParams.get("write")?.toString())

    if (ref_CH && ref_CH.current && ref_CH.current.offsetHeight > 1080) {
      setCheight('bg-[#ffffff] rounded-[8px] h-[calc(100%-72.5px)]')

    } else {
      setCheight('bg-[#ffffff] rounded-[8px] h-[100vh]')

    }


    if (searchParams.get("write")?.toString() === "1") {
      document.body.style.overflow = "hidden";

      setShowModal(true);
      setModify(false)
    }

    getHistoryList(0)
  }, [])



  const getLangList = async () => {
    try {
      const data = await (await fetch('https://playcon.net/api/common/lang/list/all', {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setLangList(data);


    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const deleteHistory = async (id: any) => {


    try {
      const confrim_msg = window.confirm("삭제하시겠습니까?");

      if(confrim_msg){
        const data = await (await fetch(`https://playcon.net/api/admin/history/delete/${id}`, {
          method: 'PUT',
          headers: {
            Authorization: "Bearer " + getCookie("accessToken"),
          },
        })).json();
  
        if(data.message === "Delete Success"){
          alert("삭제완료")
          window.location.reload();
        }else{
          alert("삭제중 오류발생")
        }
      }




    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }

  const getHistoryList = async (p: any) => {
    try {
      const data = await (await fetch(`https://playcon.net/api/admin/history/list/all/${sel_lang}/${p}`, {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + getCookie("accessToken"),
        },
      })).json();

      setHistoryList(data)

    } catch (error) {
      console.error(error);
    } finally {
      console.log('finish')
    }
  }


  const showDetail = (isShow: boolean | ((prevState: boolean) => boolean), historyId: React.SetStateAction<number>, isModify: boolean | ((prevState: boolean) => boolean)) => {
    document.body.style.overflow = "hidden";
    setShowModal(isShow);
    setHistoryId(historyId)
    setModify(isModify)
    setImgName("")
  }

  const showNew = () => {

    document.body.style.overflow = "hidden";
    setFileList(null)
    setImgString("")
    setShowModal(true);
    setModify(false)
    setSelDate("")
    setHistoryId(0)
    setImgName("")
  }


  const toogleOrder = () => {
    if (listOrder) {
      setListOrder(false)
    } else {
      setListOrder(true)
    }
  }



  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const urlToBase64 = async (file_id: any) => {
    const init = await fetch(`https://playcon.net/api/common/file/download/${file_id}`, { method: "get" })
    const blob = await init.blob()

    var file = new File([blob], `preview.png`);
   
    const base64 = await toBase64(file)
    console.log(base64)
    return base64;
  
  }



  return (
    <>
      <div className='w-full h-full flex flex-nowrap'>
        <Sidebar />

        <div className='w-[calc(100%-260px)] h-full p-[35px] bg-[#eef2f7]' ref={ref_CH}>

          {typeof langList !== 'undefined' ? <p>{langList.data?.KR}</p> : null}
          <p className='text-[25px] font-bold text-[#313a46] pb-[35px]'>히스토리(연혁)</p>



          <div className={cHeight}>
            <HistoryModal write="N" imgName={imgName} setImgName={setImgName} selDate={selDate} setSelDate={setSelDate} setShowModal={setShowModal} showModal={showModal} setHistoryId={setHistoryId} historyId={historyId} ipip={ipip} modify={modify} langList={langList} fileList={fileList} setFileList={setFileList} imgString={imgString} setImgString={setImgString} />
            <div className='p-[35px]'>
              <div className="">
                <div className=''>
                  <label className='relative hidden'>
                    <input type="text" className='border border-solid border-[#313a46] pl-[14px] pr-[46px] py-[20px] w-[410px] h-[10px] rounded text-[14px] placeholder:text-[#313a46]' placeholder='제목으로 검색 가능' />
                    <img src={search} alt="" className='absolute top-[4px] left-[374px]' />
                  </label>
                  <Link to='/admin/history/write'><button type="button" className='w-[150px] h-[44px] bg-[#313a46] rounded-[4px] text-[14px] text-[#ffffff]'>+ 등록</button></Link>
                </div>
                <div className='float-right pb-[15px] relative invisible'>

                  <button className='w-[36px] h-[36px] text-[12px] border border-[#dddddd]'>
                    ▲
                  </button>
                  <button className='w-[36px] h-[36px] ml-[6px] text-[12px] border border-[#dddddd]'>
                    ▼
                  </button>
                  {listOrder ? (
                    <>
                      <div className='absolute left-[78px] top-[-116px]'>
                        <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-[#dddddd]'>
                          최신순 ▼
                        </button>
                        <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                          최신순
                        </button>
                        <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border bg-[#ffffff] border-t-0 border-[#dddddd]'>
                          등록일순
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                    </>
                  )}

                  <button className='w-[82px] h-[36px] ml-[6px] text-[14px] border border-[#dddddd]' onClick={toogleOrder}>
                    최신순 ▼
                  </button>

                </div>
                <table className="table-auto w-full -[15px]">
                  <thead>
                    <tr>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] rounded-tl text-[#000]"><input type='checkbox' /></th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px]  text-[#000]">번호</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">썸네일</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">내용</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] font-bold text-[14px] text-[#000]">링크</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] text-center font-bold text-[14px] text-[#000]">활성화 여부</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] text-center font-bold text-[14px] text-[#000]">수정</th>
                      <th className="border-b bg-[#eef2f7] text-left py-[15px] px-[10px] text-center font-bold text-[14px] rounded-tr text-[#000]">삭제</th>
                    </tr>
                  </thead>


                  <tbody className="bg-white dark:bg-slate-800">

                    {typeof historylist !== 'undefined' ? (
                      <>
                        {Object.keys(historylist.data).map((NO, index) => (
                          <tr className="hover:bg-[#eef2f7]" key={NO}>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]"><input type='checkbox' /></td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{historylist.data[index].history_id}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]"><img src={`https://playcon.net/api/common/file/image/${historylist.data[index].file_id}`} alt="" className="w-[53px] h-[59px]" /></td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{historylist.data[index].content}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] border-solid border-[#dddddd]">{historylist.data[index].link}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] text-center border-solid border-[#dddddd]">{historylist.data[index].is_active}</td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] text-center border-solid border-[#dddddd]"><center><img src={edit_btn} alt="" onClick={(e)=>{showDetail(true,historylist.data[index].history_id,true)}} /></center></td>
                            <td className="py-[5px] px-[10px] text-[14px] align-middle border-b-[2px] text-center border-solid border-[#dddddd]"><center><img src={delete_btn} alt="" onClick={(e)=>{deleteHistory(historylist.data[index].history_id)}} /></center></td>
                          </tr>

                       ))}
                      </>
                    ) : null}



                  </tbody>

                </table>
                <div className='w-full flex flex-nowrap justify-center mt-[20px] hidden '>
                  <ul>
                    <li className='inline-block mr-[42px]'>
                      <img src={arrow_left} alt=""></img>
                    </li>
                    <li className='inline-block w-[28px] h-[28px] bg-[#313a46] text-[#ffffff] text-center'>
                      <p>1</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>2</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>3</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>4</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>5</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>6</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>7</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>8</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>9</p>
                    </li>
                    <li className='inline-block ml-[16px] w-[28px] h-[28px] text-[#969696] text-center'>
                      <p>10</p>
                    </li>
                    <li className='inline-block ml-[42px]'>
                      <img src={arrow_right} alt=""></img>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>



    </>
  );
}
export default History;